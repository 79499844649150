import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { ContainerPages } from "../../components/container";
import useVideos from "../../hooks/useVideos";
import { getTheme, getThemeContraste, heightScreen } from "../../utils/theme";
import { Box, List, ListItem, ListItemButton } from "@mui/material";
import { AccordionPastas } from "../../components/accordion";
import { StackLeft } from "../../components/stack";
import VideoPlayer from "../../components/videos";
import { Folder, PlayOff, PlayOn } from "../../components/icons";

const iniVideos = {
    caminho: "",
    nome: ""
}

export default function Home() {
    const { getVideos, videos } = useVideos();
    const [videoSelect, setVideoSelect] = useState(iniVideos);

    useEffect(() => {
        getVideos();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            setVideoSelect(videos.pastas[0].videos[0]);
        } catch {
            setVideoSelect(iniVideos);
        }
    }, [videos])

    function ListarPastas(pasta, p) {
        return <AccordionPastas key={p} icon={<Folder color={getThemeContraste()} />} pasta={pasta.nome} cor={getTheme() === 'dark' ? '#262a34' : '#fff'}>
            {
                pasta.pastas.length > 0 ? pasta.pastas.map(ListarSubPastas)
                    :
                    pasta.videos.map(ListarVideos)
            }
        </ AccordionPastas>
    }

    function ListarSubPastas(pasta, p) {
        return <AccordionPastas key={p} icon={<Folder style={{ marginLeft: '10px' }} />} pasta={pasta.nome} cor={getTheme() === 'dark' ? '#2c303b' : '#f0f0f0'}>
            {
                pasta.pastas.length > 0 ? pasta.pastas.map(ListarPastas)
                    :
                    <List>
                        {pasta.videos.map(ListarVideos)}
                    </List>
            }
        </AccordionPastas>
    }

    function ListarVideos(video, v) {
        return <ListItem key={v} >
            <ListItemButton onClick={() => setVideoSelect(video)} style={{ background: video.nome === videoSelect.nome ? '#ed3237' : 'inherit', borderRadius: '5px' }}>
                <StackLeft><span style={{ paddingTop: '5px', color: video.nome === videoSelect.nome ? 'white' : getThemeContraste() }}>{video.nome === videoSelect.nome ? <PlayOn color='white' /> : <PlayOff color={getThemeContraste()} />}</span><span style={{ color: video.nome === videoSelect.nome ? 'white' : getThemeContraste() }}>{video.nome}</span></StackLeft>
            </ListItemButton>
        </ListItem>
    }

    return (
        <ContainerPages>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item xs={12} md={4} style={{ height: heightScreen() - 100, overflow: 'auto' }}>
                    <Box p={1} pt={0}>
                        {videos ? videos.pastas.map(ListarPastas) : ''}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} style={{ height: heightScreen() - 100 }}>
                    <Box p={2} pt={0}>
                        {videoSelect.id === 0 ?
                            <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                            :
                            <VideoPlayer titulo={videoSelect.nome} url={videoSelect.caminho} />
                        }
                    </Box>
                </Grid>
            </Grid>
        </ContainerPages>
    );
}