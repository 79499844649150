// import { descript } from "..";
import { descript } from "../crypto";
import { getSession } from "../storage";

let userInit = {
    'administrador': '',
    'exp': 0, 'iss': "Redsis", 'nome': '',
    'permissoes': '',
    'servico': '',
    'sub': ''
};

export function getUser() {
    let usuario = getSession('token_sac') ? JSON.parse(descript(getSession('token_sac'))) : userInit;
    return usuario;
}

export function permRender(perm, component) {
    if (getUser().permissoes.includes(perm))
        return component;
    else
        return '';
}

export function getPerm(valor) {
    let perm = getSession('token_sac') ? JSON.parse(descript(getSession('token_sac'))).permissoes.toString() : '0';
    return perm.includes(valor);
}