import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import Home from './pages/home';
import Externo from './pages/externo';
import Config from './pages/config';

function Rotas() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/home" exact element={<Home />} />
      <Route path="/visitantes" exact element={<Home />} />
      <Route path="/externo/:cnpj" exact element={<Externo />} />
      <Route path='*' element={<h1>Not Found</h1>} />
      <Route path='/Config' element={<Config />} />
    </Routes>
  );
}

export default Rotas;
