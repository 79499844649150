import { useState } from "react";
import useToken from "./useToken";
import { getSession, setSession } from "../utils/storage";
import { Alert } from "@mui/material";

const useVideos = () => {
    const { token } = useToken();
    const [videos, setVideos] = useState();
    const [loading, setLoading] = useState(true);

    const getVideos = async (tipo) => {
        setLoading(true)
        let url = 'https://server.redsis.com.br:8085/videos';
        if (tipo)
            url = `https://server.redsis.com.br:8085/videos/${tipo}`;
        await fetch(url, {
            headers: {
                'Authorization': token
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Assuming response is JSON
            })
            .then(data => {
                setVideos(data);
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
        setLoading(false);
    }

    const postVideos = async (tipo) => {
        let json = getSession('videosNovo');

        fetch(`https://server.redsis.com.br:8085/videos/${tipo.toLowerCase()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao enviar o arquivo.');
                }
                alert('Arquivo enviado com sucesso!');
            })
            .catch(error => {
                console.error('Erro na solicitação:', error);
            });
    }


    return { postVideos, getVideos, videos, loading };
}

export default useVideos;

