import React, { useEffect, useState } from "react";
import { ContainerPages } from "../../components/container";
import useVideos from "../../hooks/useVideos";
import { getTheme, getThemeContraste } from "../../utils/theme";
import { Box, Button, ButtonGroup, FormControl, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, Stack, TextField } from "@mui/material";
import { AccordionPastasConfig } from "../../components/accordion";
import { StackLeft, StackRight } from "../../components/stack";
import { CancelIcon, CheckIcon, Delete, DownIcon, Edit, Folder, PlayOff, PlayOn, ReloadIcon, UpIcon, UploadFile } from "../../components/icons";
import { getSession, removeSession, setSession } from "../../utils/storage";
import { formatValue, listaLength } from "../../utils/format";
import { ButtonToolTip } from "../../components/button";
import { deletePasta, deleteVideo, descerPasta, descerVideo, subirPasta, subirVideo, updatePasta, updateVideo } from "../../utils/videos";
import { TitleGrupos } from "../../components/typography";

const iniPasta = { id: 0, nome: '', pastas: [], videos: [] };
const iniVideo = { id: 0, nome: '', caminho: '' };

export default function Config() {
    const { getVideos, postVideos, videos, loading } = useVideos();
    const [listVideos, setListVideos] = useState(iniPasta);
    const [videoSelect, setVideoSelect] = useState(iniVideo);
    const [pastaSelect, setPastaSelect] = useState(iniPasta);
    const [openForm, setOpenForm] = useState(false);
    const [tipoEvento, setTipoEvento] = useState('');
    const [tipoGet, setTipoGet] = useState('clientes');
    const [at, setAt] = useState(true);

    useEffect(() => {
        getVideos(tipoGet);
    }, [tipoGet])

    useEffect(() => {
        setSession('videos', JSON.stringify(videos))
    }, [loading])

    useEffect(() => {
        if (videos !== undefined)
            setListVideos(JSON.parse(getSession('videos')));
    }, [videos])

    useEffect(() => {
        if ((at === true) && (typeof (getSession('videos')) !== 'string'))
            setListVideos(JSON.parse(getSession('videos')));
        setAt(false)
    }, [at, getSession('videos')])


    function setEvento(pasta, item, evento) {
        switch (evento) {
            case 'upVideo':
                subirVideo(listVideos, pasta.id, item.id)
                setSession('videos', JSON.stringify(listVideos));
                setAt(true);
                break;
            case 'downVideo':
                descerVideo(listVideos, pasta.id, item.id)
                setSession('videos', JSON.stringify(listVideos));
                setAt(true);
                break;
            case 'upPasta':
                subirPasta(listVideos, pasta.id)
                setSession('videos', JSON.stringify(listVideos));
                setAt(true);
                break;
            case 'downPasta':
                descerPasta(listVideos, pasta.id)
                setSession('videos', JSON.stringify(listVideos));
                setAt(true);
                break;
            case 'deletePasta':
                deletePasta(listVideos, pasta.id);
                setSession('videos', JSON.stringify(listVideos));
                setAt(true);
                break;
            case 'deleteVideo':
                deleteVideo(listVideos, item.id);
                setSession('videos', JSON.stringify(listVideos));
                setAt(true);
                break;
            case 'editarPasta':
                setVideoSelect(iniVideo);
            default:
                setPastaSelect(pasta);
                setTipoEvento(evento);
                setOpenForm(true);
                break;
        }
    }

    function selectVideo(video) {
        setVideoSelect(video);
        setOpenForm(false);
    }

    function upload() {
        removeSession('videosNovo');
        setSession('videosNovo', JSON.stringify(listVideos))
        postVideos(tipoGet);
    }

    function FormItem() {
        const [novoItem, setNovoItem] = useState(iniVideo)
        const [tipo, setTipo] = useState('video');

        React.useEffect(() => {
            switch (tipoEvento) {
                case 'editarVideo':
                    setNovoItem(videoSelect);
                    setTipo('video');
                    break;
                case 'editarPasta':
                    setNovoItem(pastaSelect);
                    setTipo('pasta');
                    break;
                case 'tipoEvento':
                default:
                    if (tipo === 'pasta')
                        setNovoItem(iniPasta);
                    let novoId = formatValue(listaLength(pastaSelect.pastas) + listaLength(pastaSelect.videos) + 1);
                    setNovoItem((prevalue) => {
                        return {
                            ...prevalue,
                            id: parseInt(pastaSelect.id + novoId)
                        }
                    })
                    break;
            }

        }, [tipo]);

        const handleChange = (event) => {
            let name = event.target.name;
            let value = event.target.value;
            setNovoItem((prevalue) => {
                return {
                    ...prevalue,
                    [name]: value
                }
            })
        }

        const handleClose = () => {
            setNovoItem(iniVideo);
            setVideoSelect(iniVideo);
            // setPastaSelect(iniPasta);
            setOpenForm(false);
        }

        const salvar = () => {
            switch (tipoEvento) {
                case 'editarVideo':
                    updateVideo(listVideos, novoItem.id, novoItem);
                    break;
                case 'editarPasta':
                    updatePasta(listVideos, novoItem.id, novoItem);
                    break;
                default:
                    if (tipo === 'pasta') {
                        pastaSelect.pastas.push(novoItem)
                    } else {
                        pastaSelect.videos.push(novoItem)
                    }
                    break;
            }

            setSession('videos', JSON.stringify(listVideos));
            handleClose();
            setAt(true);
        }

        return (
            <Box mt={1}>
                <Stack spacing={2}>
                    {tipoEvento === 'editarVideo' || tipoEvento === 'editarPasta' ? '' :
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel>Tipo</InputLabel>
                            <Select
                                name='tipo'
                                fullWidth
                                value={tipo}
                                size="small"
                                onChange={(e) => setTipo(e.target.value)}
                            >
                                <MenuItem value='video'>Vídeo</MenuItem>
                                <MenuItem value='pasta'>Pasta</MenuItem>
                            </Select>
                        </FormControl>}
                    <TextField name='nome' label='Nome' fullWidth size="small" value={novoItem.nome} onChange={handleChange} />
                    {tipo === 'video' ? <TextField name='caminho' label='Caminho' fullWidth size="small" value={novoItem.caminho} onChange={handleChange} /> : ''}
                </Stack>
                <p />
                <StackRight>
                    {/* <Button variant='contained' color='green' size="small" onClick={salvar}>Salvar</Button> */}
                    {/* <Button variant='contained' color='red' size="small" onClick={handleClose}>Cancelar</Button> */}
                    <ButtonToolTip title='Salvar' icon={<CheckIcon />} color='green' onClick={salvar} />
                    <ButtonToolTip title='Cancelar' icon={<CancelIcon />} color='red' onClick={handleClose} />
                </StackRight>
            </Box>
        )
    }

    function ListarPastas(pasta, p) {
        return <AccordionPastasConfig key={p} icon={<Folder color={getThemeContraste()} />} pasta={pasta.nome} cor={getTheme() === 'dark' ? '#2c303b' : '#f0f0f0'} select={() => setPastaSelect(pasta)}
            onClick={() => setPastaSelect(pasta)}
            onClickCapture={() => setOpenForm(false)}
            onClickAdd={() => setEvento(pasta, undefined, 'novo')}
            onClickEdit={() => setEvento(pasta, iniVideo, 'editarPasta')}
            onClickRemove={() => setEvento(pasta, iniVideo, 'deletePasta')}
            onClickUp={() => setEvento(pasta, iniVideo, 'upPasta')}
            onClickDown={() => setEvento(pasta, iniVideo, 'downPasta')}
        >
            {openForm && pasta.id === pastaSelect.id ? < FormItem /> : <></>}
            {listaLength(pasta.videos) > 0 ? pasta.videos.map(ListarVideos) : ''}
            {listaLength(pasta.pastas) > 0 ? pasta.pastas.map(ListarSubPastas) : ''}
        </ AccordionPastasConfig>
    }

    function ListarSubPastas(pasta, p) {
        return <AccordionPastasConfig key={p} icon={<Folder style={{ marginLeft: '10px' }} color={getThemeContraste()} />} pasta={pasta.nome} cor={getTheme() === 'dark' ? '#262a34' : '#fff'} select={() => setPastaSelect(pasta)}
            onClick={() => setPastaSelect(pasta)}
            onClickCapture={() => setOpenForm(false)}
            onClickAdd={() => setEvento(pasta, iniVideo, 'novo')}
            onClickEdit={() => setEvento(pasta, iniVideo, 'editarPasta')}
            onClickRemove={() => setEvento(pasta, iniVideo, 'deletePasta')}
            onClickUp={() => setEvento(pasta, iniVideo, 'upPasta')}
            onClickDown={() => setEvento(pasta, iniVideo, 'downPasta')}
        >
            {openForm && pasta.id === pastaSelect.id ? < FormItem /> : <></>}
            {listaLength(pasta.videos) > 0 ? pasta.videos.map(ListarVideos) : ''}
            {listaLength(pasta.pastas) > 0 ? pasta.pastas.map(ListarSubPastas) : ''}
        </AccordionPastasConfig>
    }

    function ListarVideos(video, v) {
        return <><ListItem key={v} >
            <ListItemButton onClick={() => selectVideo(video)} style={{ background: video.nome === videoSelect.nome ? '#ed3237' : 'inherit', borderRadius: '5px 0 0 5px', height: '50px' }}>
                <StackLeft>
                    <span style={{ paddingTop: '5px' }}>
                        {video.nome === videoSelect.nome ?
                            <PlayOn color={video.nome === videoSelect.nome ? 'white' : getThemeContraste()} /> :
                            <PlayOff color={video.nome === videoSelect.nome ? 'white' : getThemeContraste()} />}
                    </span>
                    <span style={{ color: video.nome === videoSelect.nome ? 'white' : getThemeContraste() }}>{video.nome}</span>
                </StackLeft>
            </ListItemButton>
            <Box style={{ background: video.nome === videoSelect.nome ? '#ed3237' : 'inherit', borderRadius: '0 5px 5px 0', height: '45px', paddingTop: '5px' }}>
                {videoSelect.id === video.id ?
                    <ButtonGroup>
                        <ButtonToolTip title='Editar Video' icon={<Edit />} color='white' onClick={() => setEvento(iniPasta, video, 'editarVideo')} cor={getThemeContraste()} />
                        <ButtonToolTip title='Remover Video' icon={<Delete />} color='white' onClick={() => setEvento(iniPasta, video, 'deleteVideo')} />
                        <ButtonToolTip title='Mover para cima' icon={<UpIcon />} color='white' onClick={() => setEvento(pastaSelect, video, 'upVideo')} />
                        <ButtonToolTip title='Mover para baixo' icon={<DownIcon />} color='white' onClick={() => setEvento(pastaSelect, video, 'downVideo')} />
                    </ButtonGroup>
                    : ''}
            </Box>
        </ListItem>
            {openForm && video.id === videoSelect.id ?
                <Box ml={3} mr={3} mt={1}>
                    < FormItem />
                </Box>
                : <></>}
        </>
    }

    return (
        <ContainerPages
            title='Gerenciar Playlist' activeAuxBar={true}
        >
            <Box style={{ marginBottom: '5px' }}>
                <Box m={1} p={1} style={{ background: getTheme() === 'dark' ? '#262a34' : '#fff', borderRadius: '5px' }}>
                    <TitleGrupos title='Arquivo a ser modificado:' />
                    <Select
                        name='tipo'
                        fullWidth
                        value={tipoGet}
                        size="small"
                        onChange={(e) => setTipoGet(e.target.value)}

                    >
                        <MenuItem value='clientes'>Clientes</MenuItem>
                        <MenuItem value='visitantes'>Visitantes</MenuItem>
                        <MenuItem value='colaboradores'>Colaboradores</MenuItem>
                    </Select>
                </Box>
                {listVideos ? <Box p={1} pt={0}>
                    <AccordionPastasConfig key={0} icon={<Folder style={{ marginLeft: '10px' }} color={getThemeContraste()} />} pasta={listVideos ? listVideos.nome : ''} cor={getTheme() === 'dark' ? '#262a34' : '#fff'} ocultarMov={true}
                        onClickCapture={() => setOpenForm(false)}
                        onClickAdd={() => setEvento(listVideos, iniVideo, 'novo')}
                    >
                        <List>
                            {listVideos ? listVideos.pastas.map(ListarPastas) : ''}
                            {openForm && listVideos.id === pastaSelect.id ? < FormItem /> : <></>}
                        </List>
                    </AccordionPastasConfig>
                </Box> : ''}
                <Box m={1}>
                    <StackRight>
                        <Button variant="contained" size='small' color="red"><StackLeft><ReloadIcon color={'white'} /><span style={{ color: 'white' }} onClick={() => window.location.reload()}>Recarregar</span></StackLeft></Button>
                        <Button variant="contained" size='small' color="blue"><StackLeft ><UploadFile color={'white'} /><span style={{ color: 'white' }} onClick={() => upload()}>Atualizar</span></StackLeft></Button>
                    </StackRight>
                </Box>
            </Box>
            <br />
        </ContainerPages >
    );
}