import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getTheme } from '../utils/theme';

const dark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#fff',
        },
        secondary: {
            main: '#ff3d00',
            contrastText: '#ff3d00'
        },

        gray: {
            main: '#263238',
            contrastText: '#fff'
        },
        darkGray: {
            main: '#263238',
            contrastText: 'white'
        },
        green: {
            main: '#008000',
            contrastText: '#fff'
        },

        red: {
            main: '#b71c1c',
            contrastText: '#white',
        },

        white: {
            main: '#fff',
            contrastText: 'white',
        },
        orange: {
            main: '#ffa500',
            contrastText: 'white',
        },
        blue: {
            main: '#27617e',
            contrastText: 'white',
        },
        yellow: {
            main: '#FFD700',
            contrastText: '#fff'
        }
    },
});

const light = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#263238',
        },
        secondary: {
            main: '#ff3d00',
            contrastText: '#ff3d00'
        },

        gray: {
            main: '#263238',
            contrastText: '#fff'
        },
        darkGray: {
            main: '#263238',
            contrastText: 'white'
        },
        green: {
            main: '#008000',
            contrastText: '#fff'
        },

        red: {
            main: '#b71c1c',
            contrastText: 'white',
        },

        white: {
            main: '#fff',
            contrastText: 'white',
        },
        orange: {
            main: '#ffa500',
            contrastText: 'white',
        },
        blue: {
            main: '#27617e',
            contrastText: 'white',
        },
        yellow: {
            main: '#FFD700',
            contrastText: '#fff'
        }
    },
});

export default function ThemeRedsis(props) {
    if (getTheme() === 'dark') {
        return (
            <ThemeProvider theme={dark}>
                {props.children}
            </ThemeProvider>
        );
    } else {
        return (
            <ThemeProvider theme={light}>
                {props.children}
            </ThemeProvider>
        );
    }
}