export function listaLength(lista) {
    try {
        return lista.length;
    } catch {
        return 0;
    }
}

export function formatValue(valor) {
    if (valor < 10) {
        return '0' + valor;
    } else {
        return valor;
    }
}