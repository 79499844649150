import * as React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { getThemeContraste } from '../../utils/theme';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export function ButtonToolTip(props) {
    return (<>
        <HtmlTooltip title={props.title}>
            <IconButton
                id={props.id}
                aria-label={props.arialLabel}
                onClick={props.onClick}
                onClickCapture={props.onClickCapture}
                color={props.color}
                aria-controls={props.ariaControls}
                aria-haspopup={props.ariaHaspopup}
                aria-expanded={props.ariaExpanded}
            >
                {props.icon}
            </IconButton>
        </HtmlTooltip >
    </>);
}

export function ButtonLinkIcon(props) {
    return (<Link to={props.to} style={{ textDecoration: 'none', color: getThemeContraste(), marginRight: '0px' }}>
        <HtmlTooltip title={props.title}>
            <IconButton
                id={props.id}
                onClick={props.onClick}
            >
                {props.icon}
            </IconButton>
        </HtmlTooltip >
    </Link>);
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.90)',
        maxWidth: '170px',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
    },
}));