import axios from 'axios'

const api = axios.create({
  baseURL: "https://server.redsis.com.br:8085",
  // baseURL: "https://server.redsis.com.br:5555",
});

export default api;


