import { useEffect } from "react";
import useToken from "../../hooks/useToken";
import { ContainerPages } from "../../components/container";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

export default function Externo() {
    const { getTokenExterno } = useToken();
    const { cnpj } = useParams();


    useEffect(() => {
        getTokenExterno(cnpj);
        // eslint-disable-next-line
    }, [])


    return (<ContainerPages>
        <Box sx={{ textAlign: 'center', marginTop: '15%' }}>
            <CircularProgress />
        </Box>
    </ContainerPages>);
}