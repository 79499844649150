import React, { useContext } from 'react';
import NavBar from '../appbar';
import Container from '@mui/material/Container';

import { getBackground, heightDefault } from '../../utils/theme';
import { Box } from '@mui/material';

export function ContainerPages(props) {
    document.body.style.background = getBackground();

    return (
        <Container maxWidth='xxl' style={{ padding: '0px' }}>
            <NavBar activeAuxBar={props.activeAuxBar} title={props.title} btnAux={props.btnAux} />
            <Box pl={1} pr={1} mt={5} height={heightDefault()} style={{ background: 'inherit' }}>
                <Box sx={{
                    marginTop: props.activeAuxBar ? '150px' : '90px',
                    marginBottom: '20px'
                }} >
                    {props.children}
                </Box>
            </Box>
        </Container>
    );
}